import { Row, Col } from "antd";
import { withTranslation, TFunction } from "react-i18next";
import Container from "../../common/Container";

import {
  FooterSection,
  Para,
  Language,
} from "./styles";

const Footer = ({ t }: { t: TFunction }) => {
  return (
    <>
      <FooterSection id="contact">
        <Container>
          <Row justify="space-between">
            <Col lg={8} md={8} sm={12} xs={12}>
              <Language>{t("Contato")}</Language>
              <Para>Whatsapp: 11 91184-3480</Para>
              <Para>E-mail: contato@teclipei.com.br</Para>
              <Para>Instagram/TikTok: @te.clipei</Para>
            </Col>
          </Row>
        </Container>
      </FooterSection>
    </>
  );
};

export default withTranslation()(Footer);
